class GestorObd {
  constructor() {
    this.step = 1
    this.components = []
  }

  hasLoadedComponents() {
    try {
      return this.components.length > 0
    } catch (error) {}

    return false
  }

  getComponentSteps() {
    const availableSteps = this.components
      .reduce((prev, curr, index) => {
        prev[curr.pantalla] = {
          id: index + 1,
          pantalla: curr.pantalla
        }
        return prev
      }, {})

    return availableSteps
  }

  getCurrentComponent() {
    const steps = this.getComponentSteps()

    const result = Object.keys(steps).find(s => {
      return steps[s].id === this.step
    })

    if (!result) return "SignUpForm"

    return steps[result].pantalla
  }

  nextStep() {
    this.step++
  }

  getCurrentStep() {
    return this.step
  }

  isLastStep() {
    return this.step === this.components.length
  }
  
  maxStep(){
      if(!this.components) return 1
      return this.components.length
  }  
}

export default GestorObd
