import http from "./baseApi"

export default {
  async getComponentsByOrigin(origen) {
    return http.post("/obd_origin", {
      origin_client: origen
    })
  },
  getPaisesSixClovers(){
    return http.post("/countries_of_six_clovers")
  }
}